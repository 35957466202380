import React, {useState, useEffect} from 'react';
import './reports.scss'
import Loading from "../../Main/Loading/Loading";
import TextViewer from "../../../../helpers/textViewer";
import PDFGenerator from "../../PDFGenerator/PDFGenerator";
import utils from "../../../../helpers/utils";
import moment from "moment";
import SingletoneStoreClass from "../../../../Store/Store";
import prayerConfig from '../PrayersDetails/config/prayerConfig';
import tfilotParser from "../../../../helpers/TfilotOfTheDay/tfilotParser";
import CreateReport from "../../PDFGenerator/CreateReport";
import {Select} from "antd";
import config from "../../../../config";

const { Option } = Select;

const store = SingletoneStoreClass.getInstance();

const Reports = (props)=>{

    const currentDate = new Date();

    const { loading , users = [], updateData, synagogueName, base64Logo, location } = props;
    const [importantDatesFilter, setImportantDatesFilter] = useState('all');
    const [exportType, setExportType] = useState('pdf');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date(currentDate.setDate(currentDate.getDate() + 30)));
    const [rendering, setRendering] = useState(false);
    const [customizeGeneralReport, setCustomizeGeneralReport] = useState(null);

    // useEffect(() => {
    //     console.log("Plasma: ",{plasmaSetting, itemToSave});
    // }, [itemToSave, plasmaSetting]);

    if(loading || rendering) return <Loading/>

    let currentParasha = store.getParasha(location, new Date());

    const onChooseExportTypeChange = (event)=>{
        setExportType(event.target.value);
    }

    const onStartDateChange = (event)=>{
        setStartDate(new Date(event.target.value));
    }

    const onEndDateChange = (event)=>{
        setEndDate(new Date(event.target.value));
    }


    return <div className={'report-container'}>
        <TextViewer text={'reports'}/>
        <div>
            <TextViewer text={'choose_export_type'} addChar={':'}/>
            <div className={'empty-divider'}></div>
            <select onChange={onChooseExportTypeChange} value={exportType}>
                <option value={'pdf'}>pdf</option>
                <option value={'csv'}>csv</option>
            </select>
        </div>
        <hr className={'divider-report'} />
        <div>
            <button className={'list-details-actions-export-pdf'}>
                <CreateReport textTitle={TextViewer({text: 'times_community_report',addedString: currentParasha, addChar: ' - פרשת', returnString: true},)}/>
            </button>
        </div>
        <hr className={'divider-report'} />
        <div>
            <Select className={'statics-select'} dropdownMatchSelectWidth={false} showSearch allowClear
                    placeholder={TextViewer({text: 'בחר מועד', returnString: true})}
                    onChange={(e)=>{
                        setCustomizeGeneralReport(JSON.parse(e))
                    }}
            >
                {config.importantDates.map(optionObj=><Option key={JSON.stringify(optionObj)} value={JSON.stringify(optionObj)}>{`${optionObj.name}${typeof optionObj.hebrewDayAtMonth === 'number' ? ` - ${config.hebrewNumToDay[optionObj.hebrewDayAtMonth]} ${config.englishMonthToHebrew[optionObj.hebrewMonth]}` : ''}`}</Option>)}
            </Select>
            <button className={'list-details-actions-export-pdf'} disabled={!customizeGeneralReport}>
                <CreateReport isfast={customizeGeneralReport?.fast} reportName={customizeGeneralReport ? customizeGeneralReport.name : null} textTitle={` דו״ח זמנים ${customizeGeneralReport ? customizeGeneralReport.name : ''} `} fromDate={customizeGeneralReport ? store.convertHebrewDate({hebrewMonthName: customizeGeneralReport?.hebrewMonth, hebrewMonthDay: customizeGeneralReport?.hebrewDayAtMonth, location}) : null}/>
            </button>
        </div>
        <hr className={'divider-report'} />
        <div>
            <button className={'list-details-actions-export-pdf'} onClick={async ()=>{
                const pdfGenerator = new PDFGenerator({
                    base64Logo,
                    synagogueName,
                    title: TextViewer({text: 'prayers_details_short', returnString: true}),
                    type: 'prayers',
                    items: users,
                    exportType
                });
                await pdfGenerator.init();
                pdfGenerator.makePDF();
            }}><TextViewer text={'prayers_details_short'}/></button>
        </div>
        <hr className={'divider-report'} />
        <div>
            <button className={'list-details-actions-export-pdf'} onClick={async ()=>{
                const pdfGenerator = new PDFGenerator({
                    base64Logo,
                    synagogueName,
                    title: TextViewer({text: 'important_dates', returnString: true, addedString: importantDatesFilter === 'all' ? '' : ` -${importantDatesFilter}`}),
                    type: 'importantDates',
                    items: utils.getImportantDatesByUsers(users, importantDatesFilter === 'all' ? false : importantDatesFilter, location),
                    exportType
                });
                await pdfGenerator.init();
                pdfGenerator.makePDF();
            }}><TextViewer text={'important_dates'}/></button>
            <div className={'empty-divider'}></div>
            <select value={importantDatesFilter} onChange={(event)=>{setImportantDatesFilter(event.target.value)}}>
                {[<option value={'all'}>{TextViewer({text: 'all', returnString: true})}</option>].concat(prayerConfig.importantDates.objectStructure.reason.type.map(option=><option value={option}>{option}</option>))}
            </select>
        </div>
        <hr className={'divider-report'} />
        <div>
            <button className={'list-details-actions-export-pdf'} onClick={async ()=>{
                const nextSunday = moment().startOf('week').add(7, 'day');
                const weeklyReportUsers = utils.calcWeeklyImportantUsers({users, currentParasha, startDate: nextSunday.toDate(), endDate: nextSunday.add(6, 'day').toDate(), TextViewer});

                const pdfGenerator = new PDFGenerator({
                    base64Logo,
                    synagogueName,
                    title: TextViewer({text: 'generate_aliyot_weekly_report', addedString: currentParasha, addChar: ' פרשת', returnString: true}),
                    type: 'weeklyReportUsers',
                    items: weeklyReportUsers,
                    exportType
                });
                await pdfGenerator.init();
                pdfGenerator.makePDF();
            }}>
                <TextViewer text={'generate_aliyot_weekly_report' } addedString={currentParasha} addChar={' פרשת'} />
            </button>
        </div>
        <hr className={'divider-report'} />
        <div>
            <button className={'list-details-actions-export-pdf'} onClick={async ()=>{
                const aliyot = [];
                users.forEach((user, userIndex)=>{
                    if(user.aliyot){
                        user.aliyot.forEach((aliya, index)=>{
                            aliyot.push({...aliya, user, aliyahIndex: index, userIndex });
                        })
                    }
                })

                const pdfGenerator = new PDFGenerator({
                    base64Logo,
                    synagogueName,
                    title: TextViewer({text: 'aliyah_panel', returnString: true}),
                    type: 'aliyot',
                    items: aliyot.sort((a,b)=>new Date(b.aliyahTime) - new Date(a.aliyahTime)),
                    exportType
                });
                await pdfGenerator.init();
                pdfGenerator.makePDF();
            }}><TextViewer text={'aliot'}/></button>
        </div>
        <hr className={'divider-report'} />
        <div className={'export-range-time-of-day'}>
            <div>
                <button className={'list-details-actions-export-pdf'} onClick={async ()=>{

                    const calcDates = ()=>{
                        const arrayOfDates = [];
                        return new Promise((resolve=>{
                            setTimeout(() => {
                                const dateToIterate = new Date(startDate.getTime())
                                while (dateToIterate < endDate){
                                    arrayOfDates.push(new Date(dateToIterate.getTime()));
                                    dateToIterate.setDate(dateToIterate.getDate() + 1);
                                }
                                const items = [];
                                arrayOfDates.forEach(date=>{
                                    const timesOfDay = tfilotParser.calcTimesOfDayV2({
                                        date,
                                        location
                                    });
                                    items.push({...timesOfDay.reduce((acc, currentValue)=>{
                                            acc[currentValue.name] = currentValue.date;
                                            return acc;
                                        }, {}), date});
                                });
                                resolve(items);
                            }, 0);
                        }))
                    }

                    if(startDate > endDate){
                        console.warn("can't calculate startDate > endDate");
                        return;
                    }
                    setRendering(true);
                    await new Promise(resolve => setTimeout(resolve, 100))
                    const items = await calcDates();
                    setRendering(false);

                    const pdfGenerator = new PDFGenerator({
                        base64Logo,
                        synagogueName,
                        // title: TextViewer({text: 'times_of_the_day', returnString: true, addedString: `${moment(startDate).format("DD.MM.YYYY")} - ${moment(endDate).format("DD.MM.YYYY")}`}),
                        title: TextViewer({text: 'times_of_the_day', addChar: ': ',returnString: true, addedString: synagogueName}),
                        type: 'timesOfTheDay',
                        items,
                        exportType
                    });
                    await pdfGenerator.init();
                    pdfGenerator.makePDF();
                }}><TextViewer text={'times_of_the_day'}/></button>
            </div>
            <div>
                <div>
                    <TextViewer text={'from_date'} addChar={':'}/>
                    <input type={'date'} onChange={onStartDateChange} placeholder={'start'}
                           value={startDate.toISOString().substr(0, 10)}/>
                </div>
                <div>
                    <TextViewer text={'to_date'} addChar={':'}/>
                    <input type={'date'} onChange={onEndDateChange}
                           value={endDate.toISOString().substr(0, 10)}/>
                </div>
            </div>
        </div>
        <hr className={'divider-report'} />
    </div>

}

export default Reports;