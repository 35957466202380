import React, { FC,Suspense } from 'react';
import BaseComponent from "./components/baseComponent/BaseComponent";
import Offline from './components/Offline/Offline';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import Advertisements from './components/Advertisements/advertisements';
import './firebase/firebase';
import TopLevel from './components/v2/TopLevel/TopLevel';
import Welcome from './components/v2/Welcome/Welcome';

import appModes from "./AppModes";
import SingletoneStoreClass from "./Store/Store";
import BottomLinks from "./components/v2/Main/BottomLinks/BottomLinks";

const store = SingletoneStoreClass.getInstance();

class App extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            sideDrawerOpen: false
        }
    }

    componentWillUnmount() {
        store.cancelRunningRequest();
    }

    render() {
        return (
            <Suspense fallback={null}>
            <div className="App" style={{height: '100%', 'backgroundColor': 'white'}}>
                <Router>
                    <Advertisements/>
                    <TopLevel/>
                    {<mainv2>
                        {navigator.onLine ? null :<Offline/>}
                        {store.getSynagogueName() ? Object.values(appModes).map(mode => {
                            if(!mode.v2) return;
                            return <Route key={`route_${mode.link}`} exact path={`/:synagogueName${mode.link}`}
                                           render={() => {return <mode.component menuOpen={this.state.menuOpen}/>}}
                            />}).concat([
                                <Route key={`route_main`} exact path={`/`}
                                       render={() => {
                                           if(store.getSynagogueName()) return <appModes.MAIN_V2.component menuOpen={this.state.menuOpen}/>
                                           return Welcome;
                                       }}/>

                            ]) :
                            <Route key={`route_intro`} exact path={`/`} render={() => <Welcome/> }/>
                            //todo: add default route
                        }
                        <BottomLinks location={null}/>
                    </mainv2>}

                </Router>
            </div>
            </Suspense>
        );
    }
}

export default App;
