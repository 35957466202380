import React, {useState, useEffect} from 'react';

import '../PrayersDetails/PrayersDetails.scss';
import TextViewer from "../../../../helpers/textViewer";
import Loading from "../../Main/Loading/Loading";
import add_yellow_circle_logo from "../../icons/add_yellow_circle.svg";
import Update from './Update/Update';
import {useHistory} from "react-router-dom";
import {Select} from "antd";
import config from "../../../../config";
import MakePushNotification from './MakePushNotification/MakePushNotification';
import avatarLogo from "../../icons/avatar.svg";
const { Option } = Select;


const Updates = (props) => {

    const { loading, updates = [], updateData } = props;

    const [checkedItemsMap, setCheckedItemsMap] = useState({});
    const [index, setIndex] = useState(-1);
    const [search, setSearch] = useState('');
    const [cloneMode, setCloneMode] = useState(false);

    const history = useHistory();

    updates.forEach((update, index)=>{
        update.index = index;
    })


    // useEffect(() => {
    //     console.log("---Times=", {checkedItemsMap, times})
    //
    // }, [checkedItemsMap, times]);


    const onAddItemClicked = ()=>{
        setIndex(-1);
        setCloneMode(false);
        history.push({
            hash: 'messages_and_notifications',
            search: 'display_one'
        });
    }


    const renderItem = (update)=>{
        const renderKeyValue = ({key, value, type})=>{
            return <div className={'key_value'}>
                <div className={'key'}> <TextViewer text={key} addChar={':'}/></div>
                {type === 'attachment' ? <img src={value} alt="icon" className={'attachment-value'}/> : <div className={'value'}>{value}</div>}
            </div>
        }

        const onCheckClicked = (event)=>{
            const obj = JSON.parse(JSON.stringify(checkedItemsMap));
            if(event.target.checked){
                obj[update.index] = true;
            }else{
                delete obj[update.index]
            }
            setCheckedItemsMap(obj);
        }


        const onItemClicked = ()=>{
            setIndex(update.index);
            setCloneMode(false);
            history.push({
                hash: 'messages_and_notifications',
                search: 'display_one'
            })
        }

        const onCloneClicked = ()=>{
            setIndex(update.index);
            setCloneMode(true);
            history.push({
                hash: 'messages_and_notifications',
                search: 'display_one'
            });
        }

        const isActive = ()=>{
            const currentDate = new Date().getTime();
            const startDate = new Date(update.startDate).getTime();
            const expirationDate = new Date(update.expirationDate).getTime();
            const isActive = startDate < currentDate && expirationDate > currentDate;
            return isActive ? 'yes' : 'no'
        }


        return <div className={'list-item'}>
            <div className={'checkbox'}>
                <input type="checkbox" onClick={(event)=>onCheckClicked(event)} checked={checkedItemsMap[update.index]}/>
            </div>
            <div className={'preview-item'} onClick={onItemClicked}>
                {renderKeyValue({key: 'title', value: TextViewer({text: update.title, returnString: true})})}
                {renderKeyValue({key: 'content', value: TextViewer({text: update.text, returnString: true})})}
                {renderKeyValue({key: 'presented', value: TextViewer({text: isActive(), returnString: true})})}
                {update.attachmentUrl ? renderKeyValue({key: 'attachment', value: update.attachmentUrl, type: 'attachment'}) : null}
            </div>
            <div className={'list-item-divider'}></div>
            <button className={'clone-item-button'} onClick={onCloneClicked}><TextViewer text={'clone'}/></button>
        </div>
    }

    if(loading) return <Loading/>

    if(window.location.search.includes('display_one')){
        return <Update updates={updates} index={index} cloneMode={cloneMode} close={()=>{
            setIndex(-1);
            history.push({
                hash: 'messages_and_notifications',
            })
        }} updateData={updateData}/>
    }

    const onSelectAllClicked = (event)=>{
        if(event.target.checked){
            const newCheckedItemsMap = {};
            updates.forEach((update, index)=>{
                newCheckedItemsMap[index] = true;
            })
            setCheckedItemsMap(newCheckedItemsMap);
        }else{
            setCheckedItemsMap({});
        }
    }

    const onDeleteClicked = ()=>{
        let deleteAmount = 0;
        Object.keys(checkedItemsMap).forEach(key=>{
            if(checkedItemsMap[key]) deleteAmount++;
        })
        const text = TextViewer({text: 'are_u_sure_delete_items', addChar: '?', returnString: true});

        if (window.confirm(text.replace('X', deleteAmount)) == true) {
            let updatedItems = JSON.parse(JSON.stringify(updates));

            Object.keys(checkedItemsMap).forEach(key=>{
                updatedItems[key] = null;
            })
            updatedItems = updatedItems.filter(ui=>ui);
            updateData({fieldName: 'updates', data: updatedItems});
            setCheckedItemsMap({});
        }

    }

    const filterTimes = ()=>{
        return updates.filter(update=>{
            const searchableString = `${update.title}${update.text}`
            if(search && !searchableString.includes(search)) return false;
            return true;
        })
    }

    return <div className={'list-details-container'}>
        <div className={'list-details-container-title'}>
            <TextViewer text={'messages_and_notifications'}/>
            <input value={search} placeholder={' 🔎 חיפוש'} onChange={(event)=>setSearch(event.target.value)}/>
        </div>
        <div className={'list-details-container-actions'}>
            <div className={'select-all-container'}>
                <input type="checkbox" name="scales" onClick={onSelectAllClicked}/>
                <label className={'select-all-label'}><TextViewer text={'choose_all'}/></label>
            </div>
            <button className={'delete-item-button'} onClick={onDeleteClicked} disabled={Object.keys(checkedItemsMap).length === 0}><TextViewer text={'delete'}/></button>
        </div>
        <div className={'add-item-button'} onClick={onAddItemClicked}>
            <img src={add_yellow_circle_logo} alt="icon"/>
        </div>

        <div className={'list-container'}>
            {filterTimes().map((update)=>{
                return renderItem(update)
            })}
        </div>
        <MakePushNotification/>
    </div>
}

export default Updates;