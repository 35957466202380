const config = {
    "תצוה": {"words": ["זהב","קטורת","קורבן","מילואים","מכנסיים","מגבעת","אבנט","כתונת","אהרון","משכן"]},
    "כי תשא": {"words": ["מגפה","חטא","לוי","אהרון","שבת","אהליאב","בצלאל","עגל","משכן","שקל"]},
    "ויקהל": {"words": ["מנורה","כיור","מזבח","מלאכות","אהליאב","בצלאל","שולחן","כהן","משכן","שבת"]},
    "פקודי": {"words": ["אבנט","מגבעת","מצנפת","אהרון","כותנת","מעיל","חושן","אפוד","חנוכה","משכן"]},
    "ויקרא": {"words": ["עדות","אשם","חטאת","שלמים","ביכורים","מרחשת","מחבת","קורבן","מזבח","ויקרא"]},
    "צו": {"words": ["אשם","משה","דם","משחה","אהרון","חנוכה","משכן","דשן","כהנים","קורבנות"]},
    "שמיני": {"words": ["טהרה","אש","קטורת","עופות","שרצים","בהמות","חיות","אביהוא","נדב","שמיני"]},
    "תזריע": {"words": ["טבילה","נגע","טמא","משה","אהרון","תזריע","צמר","צרעת","קורבן","יולדת"]},
    "מצורע": {"words": ["טבילה","נגע","טמא","משה","אהרון","תזריע","צמר","צרעת","קורבן","יולדת"]},
    "חול המועד פסח": {"words": ["חמץ","אביב","פרעה","בכורות","הלל","קושיות","הגדה","מרור","מצה","פסח"]},
    "חוה״מ פסח": {"words": ["חמץ","אביב","פרעה","בכורות","הלל","קושיות","הגדה","מרור","מצה","פסח"]},
    "אחרי מות": {"words": ["כיפור","עריות","שעיר","שחיטה","מקדש","טהרה","אביהוא","נדב","אהרון","ויקרא"]},
    "קדשים": {"words": ["רכילות","שיבה","גר","זקן","פיגול","גנבה","שבת","אלילים","קדוש","ויקרא"]},
    "אמור": {"words": ["תרועה","תנופה","שמיני","שלמים","טרפה","נבלה","פרכת","אהרון","מקדש","נפש"]},
    "בהר": {"words": ["עבד","אחזתכם","מצרים","גאולה","תרבית","שביעית","יובל","שופר","שבתון","תרועה"]},
    "בחקתי": {"words": ["סיני","יובל","שדה","קודש","שקל","נדר","תקומה","קרי","קוממיות","לבטח"]},
    "במדבר": {"words": ["שולחן","עדות","הקהתי","בכור","לוים","מחנה","פקדים","דגל","יהודה","מצרים"]},
    "נשא": {"words": ["כהנים","נזיר","מנחה","מאררים","קדושים","מים","מעילה","מררי","שלושים","גרשון"]},
    "בהעלתך": {"words": ["פארן","חצוצרות","משכן","טמאים","פסח","בכור","מתאוננים","אספסוף","נרות","מנורה"]},
    "שלח לך": {"words": ["תרומה","לתור","ארבעים","מדבר","כנען","כלב","יהושוע","חברון","נשיא","מרגלים"]},
    "קורח": {"words": ["אדמה","מזבח","מחתה","קטורת","אלעזר","לוי","עדתו","אבירם","דתן","יצהר"]},
    "חקת": {"words": ["תורה","פרה","אדומה","אפר","טמא","מרים","סלע","מים","אדום","כנעני"]},
    "בלק": {"words": ["מגפה","פעור","ישראל","משל","מלאך","אתון","קסמים","בלעם","מדין","מואב"]},
    "פינחס": {"words": ["צלפחד","גורל","חלוקה","נחלה","פקדיהם","יריחו","מדינית","זמרי","כהן","אלעזר"]},
    "מטות": {"words": ["יאיר","מלקוח","מלחמה","אלעזר","נקמה","מדינים","נפשה","נדר","שבועה","ראשי"]},
    "מסעי": {"words": ["מקלט","מואב","מטה","כנען","גורל","התנחלתם","ירדן","רפידים","רעמסס","מסעיהם"]},
    "מטות-מסעי": {"words": ["יאיר","נקמה","מלחמה","מקלט","מואב","מלקוח","נחלה","שבועה","רפידים","מסעיהם"]},
    "דברים": {"words": ["חלוצים","ירושה","גבול","ארץ","אנשים","שבטים","תורה","ארבעים","משה","ישראל"]},
    "ואתחנן": {"words": ["מקלט","פסל","אבנים","ברית","נפשותיכם","הר","יהושוע","משה","ישראל","ארץ"]},
    "עקב": {"words": ["דבש","חלב","שבעים","ישראל","לוחות","ארבעים","מדבר","להוריש","מצרים","משפטים"]},
    "ראה": {"words": ["נידחת","נביא","טהור","טמא","ירדן","עיבל","גרזים","ארץ","קללה","ברכה"]},
    "שופטים": {"words": ["שגגה","נביא","לוים","כהנים","מלך","משפט","עדים","מצבה","שוטרים","שופטים"]},
     "כי תצא": {"words": ["צרעת","עמלק","מחנה","אדמי","כלאים","קן","ביצים","סורר","מלחמה"]},
     "כי תבוא": {"words": ["קללה","ברכה","עיבל","גריזים","מזבח","ירדן","מעשר","ראשית","פרי"]},
    // "נצבים-וילך": {"words": ["","","","","","","","",""]},
    // "האזינו": {"words": ["","","","","","","","",""]},
    // "יום כיפור": {"words": ["","","","","","","","",""]},
    // "חול המועד סוכות": {"words": ["","","","","","","","",""]},
    // "בראשית": {"words": ["","","","","","","","",""]},
    // "נח": {"words": ["","","","","","","","",""]},
    // "לך-לך": {"words": ["","","","","","","","",""]},
    // "וירא": {"words": ["","","","","","","","",""]},
    // "חיי שרה": {"words": []},
    // "תולדות": {"words": []},
    // "ויצא": {"words": []},
    // "וישלח": {"words": []},
    // "וישב": {"words": []},
    // "מקץ": {"words": []},
    // "ויגש": {"words": []},
    // "ויחי": {"words": []},
    // "שמות": {"words": []},
    // "וארא": {"words": []},
    // "בא": {"words": []},
    // "בשלח": {"words": []},
    // "יתרו": {"words": []},
    // "משפטים": {"words": []},
    // "תרומה": {"words": []},
    "שביעי של פסח": {"words": ["חמץ","אביב","פרעה","בכורות","הלל","קושיות","הגדה","מרור","מצה","פסח"]},
    "תזריע-מצורע": {"words": ["טבילה","נגע","מצורע","משה","נגע","תזריע","צמר","צרעת","קורבן","יולדת"]},
    "אחרי מות-קדשים": {"words": []},
    "בהר-בחקתי": {"words": []},
    "נצבים": {"words": []},
    "וילך": {"words": []},
    "חוה״מ סוכות": {"words": []},
    "ויקהל-פקודי": {"words": []},
    "פסח": {"words":  ["חמץ","אביב","פרעה","בכורות","הלל","קושיות","הגדה","מרור","מצה","פסח"]},
    "שבועות": {"words": []},
    "חקת-בלק": {"words": []},
    "ראש השנה": {"words": []},
    "סוכות": {"words": []},
    "שמיני עצרת": {"words": []}
}

export default config;