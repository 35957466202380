import Edit from './components/v2/Edit/Edit';
import Unisyn from './components/AppPages/Unisyn/unisyn';
import MainV2 from './components/v2/Main/Main';
import DonationV2 from './components/v2/Main/Donation/Donation';
import NotificationsV2 from './components/v2/Notifications/Notifications';
import InfoV2 from './components/v2/Main/Info/Info';
import GematriaCalculator from './components/v2/GematriaCalculator/GematriaCalculator';
import JerusalemCompass from './components/v2/JerusalemCompass/JerusalemCompass';
import Kids from './components/v2/Kids/Kids';
import PersonaInfo from './components/v2/PersonaInfo/PersonaInfo';
import React from "react";
import ExternalUrl from './components/v2/Main/ExternalUrl/externalUrl';
import PersonalHebrewDatesManager from './components/v2/PersonalHebrewDatesManager/PersonalHebrewDatesManager';


import TextViewer from '../src/helpers/textViewer';



const modes = {
    MAIN_V2: {
        name: <TextViewer text={'main'}/>,
        link: "/",
        component: MainV2,
        viewOnMainPage: false,
        viewOnToolbar: false,
        v2:true
    },
    DONATION_V2: {
        name: <TextViewer text={'donate'}/>,
        link: "/donation_v2",
        component: DonationV2,
        viewOnMainPage: false,
        viewOnToolbar: false,
        flexGrow: 1,
        v2:true
    },
    INFO_V2: {
        name: <TextViewer text={'about_the_shul'}/>,
        link: "/info_v2",
        component: InfoV2,
        viewOnMainPage: false,
        viewOnToolbar: false,
        flexGrow: 2,
        v2: true,
    },
    EDIT: {
        name: <TextViewer text={'gabbai_setting'}/>,
        link: "/edit",
        component: Edit,
        viewOnMainPage: true,
        viewOnToolbar: true,
        v2: true,
        v1: true
    },
    UNI_SYNAGOGUE: {
        name: <TextViewer text={'union_of_synagogues'}/>,
        link: "/unisyn",
        component: Unisyn,
        viewOnMainPage: true,
        viewOnToolbar: true,
        flexGrow: 2,
        v2: true,
        v1: true
    },
    NOTIFICATIONS: {
        name: 'notifications',
        link: "/notifications",
        component: NotificationsV2,
        viewOnMainPage: false,
        viewOnToolbar: false,
        flexGrow: 2,
        v2: true
    },
    GEMATRIA_CALCULATOR: {
        name: 'gematria-calculator',
        link: "/gematria",
        component: GematriaCalculator,
        viewOnMainPage: false,
        viewOnToolbar: false,
        flexGrow: 2,
        v2: true,
        v1: true

    },
    JERUSALEM_COMPASS: {
        name: 'jerusalem-compass',
        link: "/jerusalem-compass",
        component: JerusalemCompass,
        viewOnMainPage: false,
        viewOnToolbar: false,
        flexGrow: 2,
        v2: true,
        v1: true
    },
    // todo: use this for Sidur:
    // SIDUR: {
    //     name: "סידור",
    //     link: "/sidur",
    //     component: Sidur,
    //     viewOnMainPage: false
    // },
    EXTERNAL_URL: {
        name: 'external url',
        link: "/external-url",
        component: ExternalUrl,
        viewOnMainPage: false,
        viewOnToolbar: false,
        flexGrow: 2,
        v2: true,
        v1: true
    },
    PERSONAL_HEBREW_DATE_MANAGER: {
        name: 'Personal Hebrew Dates Manager',
        link: "/hebrew-date",
        component: PersonalHebrewDatesManager,
        viewOnMainPage: false,
        viewOnToolbar: false,
        flexGrow: 2,
        v2: true,
        v1: true
    },
    KIDS: {
        name: 'Kids',
        link: "/kids",
        component: Kids,
        viewOnMainPage: false,
        viewOnToolbar: false,
        flexGrow: 2,
        v2: true,
        v1: true
    },
    PERSONAL_INFO: {
        name: 'personal_info',
        link: "/personal_info",
        component: PersonaInfo,
        viewOnMainPage: false,
        viewOnToolbar: false,
        flexGrow: 2,
        v2: true,
        v1: true
    },
};

export default modes;