import SingletoneStoreClass from "../../../../../Store/Store";
import config from "../../../../../config";


const PRAYER_CONFIGS = {
    name: {
        keyName: 'name',
        text_view_name: 'first_name',
        required: true,
        default: ''
    },
    family: {
        keyName: 'family',
        text_view_name: 'last_name',
        required: true,
        default: ''
    },
    picture: {
        keyName: 'picture',
        text_view_name: 'prayer_picture',
        type: 'uploadFile',
        required: false,
        base64Mode: true,
        default: ''
    },
    isMember: {
        keyName: 'isMember',
        text_view_name: 'is_member',
        type: 'checkbox',
        required: false,
        default: false,
        adminViewOnly: true
    },
    lastPaymentYear: {
        keyName: 'lastPaymentYear',
        text_view_name: 'last_payment_year',
        required: false,
        adminViewOnly: true,
        type: 'select',
        options: ["תשפ״ד","תשפ״ה","תשפ״ו","תשפ״ז"]
    },
    email: {
        keyName: 'email',
        text_view_name: 'email',
        required: false,
        default: ''
    },
    fatherName: {
        keyName: 'fatherName',
        text_view_name: 'father_name',
        required: false,
        default: ''
    },
    motherName: {
        keyName: 'motherName',
        text_view_name: 'mother_name',
        required: false,
        default: ''
    },
    type: {
        keyName: 'type',
        text_view_name: 'type',
        type: 'select',
        options: ["כהן","לוי","ישראל"],
        required: true,
        default: 'ישראל'
    },
    gender: {
        keyName: 'gender',
        text_view_name: 'gender',
        type: 'select',
        options: ["זכר","נקבה"],
        required: false,
        default: 'זכר'
    },
    aliaName: {
        keyName: 'aliaName',
        text_view_name: 'alia_name',
        required: false,
        default: ''
    },
    parashatBarMizva: {
        keyName: 'parashatBarMizva',
        text_view_name: 'parashat_bar_mizva',
        type: 'select',
        options: SingletoneStoreClass.getParshiot(),
        required: true,
        default: SingletoneStoreClass.getParshiot()[0]
    },
    phoneNumber: {
        keyName: 'phoneNumber',
        text_view_name: 'phoneNumber',
        type: 'tel',
        required: true,
        validation: (value)=>value?.length === 10,
        default: ''
    },
    birthdayHebrewDay: {
        keyName: 'birthdayHebrewDay',
        text_view_name: 'birthday_hebrew_day',
        type: 'select',
        options: Object.values(config.hebrewNumToDay),
        required: true,
        default: Object.values(config.hebrewNumToDay)[0]
    },
    birthdayHebrewMonth: {
        keyName: 'birthdayHebrewMonth',
        text_view_name: 'birthday_hebrew_month',
        type: 'select',
        options: Object.values(config.hebrewNumToMonth),
        required: true,
        default: Object.values(config.hebrewNumToMonth)[0]
    },
    importantDates: {
        keyName: 'importantDates',
        text_view_name: 'important_dates',
        text_view_button_name: 'add_important_date',
        type: 'arrayOfObjects',
        objectStructure: {
            reason: {
                order: 2,
                text_view_name: 'מה הארוע ?',
                type: ["יארצייט","יום הולדת","יום נישואין", "בר מצווה", "בת מצווה"],
                default: "יארצייט"
            },
            relation: {
                order: 3,
                type: ["אבא","אמא","בעל","רעיה","דוד","דודה","אח","אחות","ילד","ילדה","סבא","סבתא","חם","חמות","אחר"],
                default: "אבא"
            },
            hebrewDay: {
                order: 4,
                type: Object.values(config.hebrewNumToDay),
                default: Object.values(config.hebrewNumToDay)[0]

            },
            hebrewMonth: {
                order: 5,
                type: Object.values(config.hebrewNumToMonth),
                default: Object.values(config.hebrewNumToMonth)[0]
            },
            name: {
                order: 6,
                type: 'string',
                text_view_name: 'full_name'
            },
        },
        required: false,
        default: []
    },
    familyMembers: {
        keyName: 'familyMembers',
        text_view_name: 'family_members',
        text_view_button_name: 'add_family_members',
        type: 'arrayOfObjects',
        objectStructure: {
            name: {
                order: 1,
                type: 'string',
                text_view_name: 'name'
            },
            family: {
                order: 2,
                type: 'string',
                text_view_name: 'family'
            },
            gender: {
                order: 3,
                type: ["זכר","נקבה"],
                default: "זכר"
            },
            relation: {
                order: 4,
                type: ["ילד","ילדה","בעל","רעיה","אחר"],
                default: "ילד"
            },
            viewOnly_1: {
                order: 4,
                type: 'viewOnly',
                text_view_only: 'birthday'
            },
            // birthdayDate: {
            //     order: 8,
            //     type: 'selectDate',
            //     default: ''
            // },
            hebrewDay: {
                order: 4,
                type: Object.values(config.hebrewNumToDay),
                default: Object.values(config.hebrewNumToDay)[0]

            },
            hebrewMonth: {
                order: 5,
                type: Object.values(config.hebrewNumToMonth),
                default: Object.values(config.hebrewNumToMonth)[0]
            },
        },
        required: false,
        default: []
    }
}

export default PRAYER_CONFIGS;