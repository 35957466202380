import React, {useEffect, useState, useRef} from 'react';
import './fonts/Heebo-Black-normal'
import './fonts/Heebo-Regular-normal'
import './fonts/Heebo-Thin-normal'
import './fonts/Heebo-Bold-normal'
import './fonts/Heebo-Light-normal'
import './fonts/Heebo-ExtraLight-normal'
import { jsPDF } from "jspdf";
import SingletoneStoreClass from "../../../Store/Store";
import TextViewer from "../../../helpers/textViewer";
import translateText from "../../../helpers/translateText";
import utils from "../../../helpers/utils";
import tfilotParser from "../../../helpers/TfilotOfTheDay/tfilotParser";
import base64Images from './base64/images'
import QRCode from 'qrcode'
import {analyticsAddLogEvent} from "../../../firebase/firebase";
import moment from "moment";


const { dateToHH_MM } = utils;

const store = SingletoneStoreClass.getInstance();
const daysToAlpha = {
    0: "sunday",
    1: "monday",
    2: "tuesday",
    3: "wednesday",
    4: "thursday",
    5: "friday",
    6: "saturday",
};
const CreateReport = (props)=>{

    const {textTitle, fromDate, reportName = null, isfast= false} = props;

    const [loading, setLoading] = useState(true);
    const [location, setLocation] = useState(null);
    const [lessonsTimes, setLessonsTimes] = useState({});

    const [donationObj, setDonationObj] = useState(null);
    const [useYeshivaTimes, setUseYeshivaTimes] = useState(true);
    const [updates, setUpdates] = useState('');
    const [times, setTimes] = useState(null);
    const [externalFormDetails, setExternalFormDetails] = useState('');
    const [externalFormUrl, setExternalFormUrl] = useState('');
    const [attachmentUrl, setAttachmentUrl] = useState('');
    const [tvarToraYoutubeLink, SetTvarToraYoutubeLink] = useState('');
    const [dvarTora, setDvarTora] = useState('');
    const [plasmaMode, setPlasmaMode] = useState(false);
    const [base64Logo, setBase64Logo] = useState(null);
    const [name, setName] = useState(null);
    const [plasmaDonation, setPlasmaDonation] = useState('');
    const [holidayTimes, setHolidayTimes] = useState({});
    const [tfilotTimes, setTfilotTimes] = useState({});
    const [holidayTimesMetadata, setHolidayTimesMetadata] = useState(null);
    const [rendering, setRendering] = useState(false);
    const [qrCodeBase64, setQrCodeBase64] = useState('');
    const [shabatEnterMinBeforeSunset, setShabatEnterMinBeforeSunset] = useState(null);
    const [shabatExitMinAfterStarsCameOut, setShabatExitMinAfterStarsCameOut] = useState(null);



    useEffect(() => {
        const fetchData = async ()=>{
            let { name, location, extraDetails, donation, updates, dvarTora, holidayTimes, tfilotTimes, lessonsTimes, times } = await store.getSynagoguePublicValues({keys: ['name', 'location', 'extraDetails', 'donation', 'updates', 'dvarTora', 'holidayTimes', 'tfilotTimes', 'lessonsTimes', 'times']});
            setLocation(location);
            setTimes(times);
            setUseYeshivaTimes(extraDetails.useYeshivaTimes);
            setExternalFormDetails(extraDetails.externalFormDetails);
            setExternalFormUrl(extraDetails.externalFormUrl);
            setAttachmentUrl(extraDetails.attachmentUrl);
            SetTvarToraYoutubeLink(extraDetails.tvarToraYoutubeLink);
            setBase64Logo(extraDetails.base64Logo);
            setPlasmaDonation(extraDetails.plasmaDonation);
            setDvarTora(dvarTora);
            setDonationObj(donation);
            setUpdates(extraDetails.updates);
            setShabatEnterMinBeforeSunset(extraDetails.shabatEnterMinBeforeSunset);
            setShabatExitMinAfterStarsCameOut(extraDetails.shabatExitMinAfterStarsCameOut);
            setTfilotTimes(tfilotTimes);
            setHolidayTimes(holidayTimes);
            setHolidayTimesMetadata(extraDetails.holidayTimesMetadata);
            setLessonsTimes(lessonsTimes);
            const base64 = await QRCode.toDataURL(`${window.location.origin}/${window.location.pathname.split("/")[1]}`, {type: "png"});
            setQrCodeBase64(base64);



            setName(name);
            setLoading(false);
        };
        fetchData();
    }, []);

    const getFridayOfCurrentWeek = () => {
        const today = new Date();
        const first = today.getDate() - today.getDay() + 1;
        const fifth = first + 4;

        const friday = new Date(today.setDate(fifth));

        return friday;
    }

    const getPrayersAndStudies = ({date})=>{
        if(times){
            const timesToDisplay = tfilotParser.calcTimesV2({times, date, location, useYeshivaTimes, types:['tfila', 'lesson'], shabatExitMinAfterStarsCameOut, shabatEnterMinBeforeSunset});
            return utils.sortTimes([...timesToDisplay]);
        }else{
            const tfilotOfTheDay = tfilotParser.calcTfilotTimesV2({
                tfilotTimes,
                holidayTimes,
                holidayTimesMetadata,
                date,
                location,
                useYeshivaTimes,
                shabatExitMinAfterStarsCameOut,
                shabatEnterMinBeforeSunset
            });
            const lessonsOfTheDay = tfilotParser.calcLessonsV2({
                lessonsTimes,
                date,
                shabatExitMinAfterStarsCameOut,
                shabatEnterMinBeforeSunset
            });
            return utils.sortTimes([...tfilotOfTheDay, ...lessonsOfTheDay])
        }

    }



    const onClick = ()=>{
        analyticsAddLogEvent({eventName: 'generate_weekly_pdf_event', params: { name: utils.getSynagogueName()}});

        const currentParasha = reportName ? reportName : store.getParasha(location);
        const shabbatEnter = store.getShabatEnter(location, null, {useYeshivaTimes, shabatEnterMinBeforeSunset, date: fromDate ? fromDate : null, useCurrentDate: !!fromDate});
        const shabbatExit = store.getShabatExit(location, null, {useYeshivaTimes, shabatExitMinAfterStarsCameOut, date: fromDate ? new Date(fromDate.getTime() + (24 * 60 * 60 * 1000)): null, useCurrentDate: !!fromDate});
        const synagogue_name = store.getSynagogueName();

        const nextFriday = getFridayOfCurrentWeek();
        const nextSaturday = new Date(nextFriday.getTime() + (24 * 60 * 60 * 1000));
        const nextSunday = new Date(nextSaturday.getTime() + (24 * 60 * 60 * 1000));
        const nextMonday= new Date(nextSunday.getTime() + (24 * 60 * 60 * 1000));
        const nextTuesday= new Date(nextMonday.getTime() + (24 * 60 * 60 * 1000));
        const nextWednesday= new Date(nextTuesday.getTime() + (24 * 60 * 60 * 1000));
        const nextThursday= new Date(nextWednesday.getTime() + (24 * 60 * 60 * 1000));

        const fridayEvents = getPrayersAndStudies({date: nextFriday});
        const saturdayEvents = getPrayersAndStudies({date: nextSaturday});
        const sundayEvents = getPrayersAndStudies({date: nextSunday});
        const mondayEvents = getPrayersAndStudies({date: nextMonday});
        const tuesdayEvents = getPrayersAndStudies({date: nextTuesday});
        const wednesdayEvents = getPrayersAndStudies({date: nextWednesday});
        const thursdayEvents = getPrayersAndStudies({date: nextThursday});

        const filterUpdates = utils.filterUpdates({updates});

        var doc = new jsPDF({
            unit: 'cm',
            format: 'a4' //21 x 29.7 cm
        });
        doc.setFont("Heebo-Regular");

        const url = `${window.location.origin}/${window.location.pathname.split("/")[1]}`;

        const hebrewMode = localStorage.getItem('APP_LANGUAGE') === 'heb';


        const renderStatic = ()=>{
            doc.setR2L(false);
            doc.setFontSize(10);
            doc.setTextColor('058ED9');
            if(hebrewMode) doc.setR2L(true);
            doc.text(translateText({text: 'barcode_scan'}), 2.2, 24.9, { align: 'center'});
            doc.setR2L(false);

            doc.addImage(qrCodeBase64, 'JPEG', 0.7, 25, 3, 3);

            doc.textWithLink(url, 1, 29, {url});

            doc.setTextColor('#0D2431');

            doc.setFontSize(15);
            // doc.setR2L(true);

            doc.addImage(base64Images.logo, 'JPEG', 1.4, 0.5, 1, 1);
            doc.addImage(base64Images.mySynagogueTxt, 'JPEG', 0.5, 1.7, 3, 0.5);

            if(base64Logo){
                doc.addImage(base64Logo, 'JPEG', 18.7, 0.5, 1.8, 1.8);
            }
            if(hebrewMode) doc.setR2L(true);
        }

        const renderEvents = ({date, events, lineHeight = 1, title})=>{
            if(events.length === 0) return;
            doc.setFont("Heebo-Black");
            doc.text(translateText({text: title || daysToAlpha[date.getDay()]}), hebrewMode ? 19 : 2, line, { align: hebrewMode ? 'right' : 'left'});
            doc.setFont("Heebo-Light");
            console.log("evetns:",events)
            const minyanNames = utils.getTimesStatics(events).minyanNames;
            console.log(minyanNames);

            const renderEventsBulk = ({bulkOfEvents})=>{
                bulkOfEvents.forEach(({name, date})=>{
                    addLine({lineHeight});
                    let text = translateText({text: ` - ${name}`, addChar: dateToHH_MM(date), addBefore: true});
                    if(hebrewMode){
                        text = text.replace(')','(');
                        text = text.replace('(',')');
                    }
                    doc.text(text, hebrewMode ? 19 : 2, line, { align: hebrewMode ? 'right' : 'left'});
                })
            }

            if(minyanNames && minyanNames.length > 1){
                minyanNames.forEach(minyanName=>{
                    addLine({lineHeight});
                    const currentFontSize = doc.getFontSize();
                    doc.setFont("Heebo-Bold");
                    doc.setFontSize(12);
                    doc.text(translateText({text: minyanName, addChar: ':'},), hebrewMode ? 19 : 2, line, { align: hebrewMode ? 'right' : 'left'});
                    doc.setFont("Heebo-Light");
                    doc.setFontSize(currentFontSize);
                    renderEventsBulk({bulkOfEvents: events.filter(event=>event.minyan === minyanName)});
                })

            }else{
                renderEventsBulk({bulkOfEvents: events});
            }
        }

        renderStatic();

        let line = 2;

        const addLine = ({lineHeight=1, newPageSetting})=>{
            line = line + lineHeight;
            if(line >= 20){
                const fontSize = doc.getFontSize()

                doc.addPage();
                renderStatic();
                if(newPageSetting) newPageSetting();
                if(fontSize) doc.setFontSize(fontSize);
                line = 4;
            }
        }

        doc.setFont("Heebo-Black");
        doc.textWithLink(name, 10.5, line, {url, align: 'center'});

        doc.setFont("Heebo-Regular");

        addLine({});
        doc.text(translateText({text: 'torha_portion', addChar: ':' ,addedString: currentParasha}), 10.5, line, 'center');
        addLine({});
        doc.text(translateText({text: isfast ? 'start_fast' : 'candle_lighting', addChar: ':' ,addedString: dateToHH_MM(shabbatEnter)}), 10.5, line, 'center');
        addLine({});
        doc.text(translateText({text: isfast ? 'end_fast' : 'havdalah', addChar: ':' ,addedString: dateToHH_MM(shabbatExit)}), 10.5, line, 'center');
        addLine({});
        renderEvents({date: fromDate || nextFriday, events: fromDate ? getPrayersAndStudies({date: fromDate}) : fridayEvents});
        addLine({})
        renderEvents({date: fromDate ?  new Date(fromDate.getTime() + (24 * 60 * 60 * 1000)) : nextSaturday, events: fromDate ? getPrayersAndStudies({date:  new Date(fromDate.getTime() + (24 * 60 * 60 * 1000))}) : saturdayEvents});
        addLine({});
        if(filterUpdates.length > 0 ){
            doc.setFont("Heebo-Black")
            doc.text(translateText({text: 'news_and_updates'}), 19,line, { align: 'right'});
            doc.setFont("Heebo-Regular");
            filterUpdates.forEach(({text, title})=>{
                if(!text && !title) return; // todo: support show attachments
                addLine({lineHeight: 0.9});


                if(title){
                    doc.setFont("Heebo-Regular");
                    doc.text(`• ${title}${text ? ':' : ''}`, 19, line, { align: 'right', maxWidth: 18});
                    line = line + 0.6;
                }

                const updateLine = `${!title ? '• ' : ''}${text ? text.split('\n').join(' ,') : ''}`;
                if(title) doc.setFont("Heebo-Thin");
                else doc.setFont("Heebo-Regular");
                if(updateLine) doc.text(updateLine, 19, line, { align: 'right', maxWidth: 18});
                if(updateLine && updateLine.length > 85){
                    console.log(`long text: ${updateLine} - length is: ${updateLine.length} - adding: ${Math.floor(updateLine.length / 100)} lines`)
                    addLine({lineHeight: Math.floor(updateLine.length / 100)})
                }
            })
        }
        addLine({});

        if((sundayEvents.length || mondayEvents.length || tuesdayEvents.length || wednesdayEvents.length || thursdayEvents.length) && !fromDate){
            doc.addPage();

            renderStatic();

            const eventsSetting = ()=>{
                doc.setFont("Heebo-Black");
                doc.text(name, 10.5, line, 'center');
                doc.setFont("Heebo-Regular");
            }

            line = 2;
            eventsSetting();

            addLine({});
            doc.text(translateText({text: 'weekday_schedule', addChar: ':'}), 10.5, line, 'center');
            addLine({});
            const totalEventsLength = (sundayEvents.length || 1) + (mondayEvents.length || 1) + (tuesdayEvents.length || 1) + (wednesdayEvents.length || 1) + (thursdayEvents.length || 1);

            let lineHeight;
            let fontSize;
            if(totalEventsLength < 20){
                doc.setFontSize(15);
                fontSize = 15;
                lineHeight = 1;
            }else if(totalEventsLength < 30){
                doc.setFontSize(12.5);
                fontSize = 12.5;
                lineHeight = 0.75;
            }else if(totalEventsLength < 40){
                doc.setFontSize(11);
                fontSize = 11;
                lineHeight = 0.6;
            }else{
                doc.setFontSize(10);
                fontSize = 10;
                lineHeight = 0.5;
            }

            addLine({lineHeight});

            const day_1 = JSON.stringify(sundayEvents.map(e=>`${dateToHH_MM(e.date)}`));
            const day_2 = JSON.stringify(mondayEvents.map(e=>`${dateToHH_MM(e.date)}`));
            const day_3 = JSON.stringify(tuesdayEvents.map(e=>`${dateToHH_MM(e.date)}`));
            const day_4 = JSON.stringify(wednesdayEvents.map(e=>`${dateToHH_MM(e.date)}`));
            const day_5 = JSON.stringify(thursdayEvents.map(e=>`${dateToHH_MM(e.date)}`));

            if(day_1 == day_2 && day_3 == day_4 && day_5 === day_1 && day_2 == day_4 && day_3 === day_5){
                console.info('all events ae identical!');
                renderEvents({date: nextSunday, events: sundayEvents, lineHeight, title: translateText({text: 'times_of_prayers_and_studies'})});

            }else{
                renderEvents({date: nextSunday, events: sundayEvents, lineHeight});
                addLine({lineHeight});
                renderEvents({date: nextMonday, events: mondayEvents, lineHeight});
                addLine({lineHeight});
                renderEvents({date: nextTuesday, events: tuesdayEvents, lineHeight});
                addLine({lineHeight});
                renderEvents({date: nextWednesday, events: wednesdayEvents, lineHeight});
                addLine({lineHeight});
                renderEvents({date: nextThursday, events: thursdayEvents, lineHeight});
            }

        }


        const pdfName = `${new Date().toLocaleDateString()}_${currentParasha}_${synagogue_name}`.split(' ').join('_');


        doc.save(`${pdfName}.pdf`);

    }

    if(!store.getSynagogueName()) return null;

    return <span onClick={onClick}>{textTitle}</span>


}

export default CreateReport;