import React, {useEffect, useState} from 'react';
import './NextShbbat.scss';
import SingletoneStoreClass from "../../../../Store/Store";
import TextViewer from "../../../../helpers/textViewer";
import candle from "../../icons/candle.svg";
import candles from "../../icons/candles.svg";
import utils from "../../../../helpers/utils";
import {Select} from "antd";
import config from "../../../../config";
import moment from "moment/moment";

const { dateToHH_MM } = utils;
const store = SingletoneStoreClass.getInstance();


let cache = [];

const NextShbbat = (props) => {

    const {loading, location, useYeshivaTimes, showOne = false, shabatEnterMinBeforeSunset, shabatExitMinAfterStarsCameOut } = props;

    // const calcNextTimes = ({nextFollowDays = 10, returnFirstItem})=>{
    //     // console.log("START--------calcNextTimes");
    //     if(cache.length > 0 && !returnFirstItem){
    //         return cache;
    //     }
    //
    //
    //     let startTime = new Date();
    //
    //     const nextTimeEvents = [];
    //     const date = new Date();
    //
    //
    //     if(date.getDay() === 6 && date.getHours() < 21){
    //         date.setDate(date.getDate() - 1); //handling case of showing next shabat on shabat day
    //     }
    //
    //     for(let i =0; i <nextFollowDays; i++){
    //         const nextDate = new Date(date);
    //         const lastDay = new Date(date);
    //         nextDate.setDate(nextDate.getDate() + 1);
    //         lastDay.setDate(lastDay.getDate() - 1);
    //
    //         const {monthName, dayOfMonth} = store.getHebrewDayAndMouthAndYear({date, location});
    //         const importantDate = config.importantDates.find(importantDate=>importantDate.showHolidayStartAndEnd && importantDate.hebrewMonth === monthName && importantDate.hebrewDayAtMonth === dayOfMonth);
    //         if(importantDate){
    //             if(importantDate.fast){
    //                 //todo calc yom kipir + 9 beav differently: https://www.yeshiva.org.il/ask/1754
    //                 const timesObj = store.getTimesForRelativeCalc(location, { dateToCalc: date });
    //                 if((importantDate.hebrewMonth === 'Av' && importantDate.hebrewDayAtMonth === 9) || importantDate.hebrewMonth === 'Tishrei' && importantDate.hebrewDayAtMonth === 10){
    //                     const lastDayTimesObj = store.getTimesForRelativeCalc(location, { dateToCalc: lastDay });
    //                     const item = {
    //                         parasha: importantDate.name,
    //                         shabbatEnter: (importantDate.hebrewMonth === 'Tishrei' && importantDate.hebrewDayAtMonth === 10) ? store.getShabatEnter(location, null, {useYeshivaTimes, date, useCurrentDate: true, shabatEnterMinBeforeSunset}) : lastDayTimesObj.sunset,
    //                         shabbatExit: timesObj.tzeit,
    //                         fast: true
    //                     }
    //                     nextTimeEvents.push(item)
    //                     if(returnFirstItem) return [item];
    //                 }else{
    //                     const item = {
    //                         parasha: importantDate.name,
    //                         shabbatEnter: timesObj.alot_hashacher,
    //                         shabbatExit: timesObj.tzeit,
    //                         fast: true
    //                     }
    //                     nextTimeEvents.push(item);
    //                     if(returnFirstItem) return [item];
    //                 }
    //             }else{
    //                 const item = {
    //                     parasha: importantDate.name,
    //                     shabbatEnter: store.getShabatEnter(location, null, {useYeshivaTimes, date, useCurrentDate: true, shabatEnterMinBeforeSunset}),
    //                     shabbatExit: store.getShabatExit(location, null, {useYeshivaTimes, nextDate, useCurrentDate: true, shabatExitMinAfterStarsCameOut})
    //                 };
    //                 if(!nextTimeEvents.find(event=>event.parasha === importantDate.name)) nextTimeEvents.push(item);
    //                 if(returnFirstItem) return [item];
    //             }
    //
    //         }
    //         if(date.getDay() === 5){
    //             const item = {
    //                 parasha: store.getParasha(location, date),
    //                 shabbatEnter: returnFirstItem ? store.getShabatEnter(location, null, {useYeshivaTimes, date, shabatEnterMinBeforeSunset}) : null, //heavy calculation - need to be calculated on demand only
    //                 shabbatExit: returnFirstItem ? store.getShabatExit(location, null, {useYeshivaTimes, date, shabatExitMinAfterStarsCameOut}) : null, //heavy calculation - need to be calculated on demand only
    //                 date: new Date(date)
    //             }
    //             nextTimeEvents.push(item);
    //             if(returnFirstItem) return [item];
    //         }
    //
    //         date.setDate(date.getDate() + 1);
    //     }
    //     if(!returnFirstItem){
    //         cache = nextTimeEvents;
    //     }
    //     let endTime = new Date();
    //     // console.log("END--------calcNextTimes", endTime - startTime );
    //
    //     return nextTimeEvents;
    // }


    // const [nextTimes, setNextTimes] = useState(calcNextTimes({returnFirstItem: true}));
    const [nextTimes, setNextTimes] = useState(utils.calcNextTimes({ returnFirstItem: true, shabatEnterMinBeforeSunset, shabatExitMinAfterStarsCameOut, useYeshivaTimes, location}));


    useEffect(() => {
        // setNextTimes(calcNextTimes({nextFollowDays: 100, returnFirstItem: false}));
        setNextTimes(utils.calcNextTimes({nextFollowDays: 100, returnFirstItem: false, shabatEnterMinBeforeSunset, shabatExitMinAfterStarsCameOut, useYeshivaTimes, location}))
    }, []);

    const [dateToShow, setDateToShow] = useState(nextTimes[0]);

    if(loading){
        return <div className={'next-shabbat-container'}>
            <TextViewer text={"loading"} addedString={'...'}/>
        </div>
    }



    const renderTimeItem = ({icon, time, name})=>{
        return <div className={'enter-exit-shabbat-item'}>
            <img className={"enter-exit-shabbat-item-icon"} src={icon}/>
            <div className={"enter-exit-shabbat-time bold-text"}> {dateToHH_MM(time)}</div>
            <div className={"enter-exit-shabbat-name"}><TextViewer text={name}/></div>
        </div>
    }

    const onParashaSelected = (index)=>{
        if(!nextTimes[index].shabbatEnter || !nextTimes[index].shabbatExit){
            setDateToShow({...nextTimes[index],
                shabbatEnter: store.getShabatEnter(location, null, {useYeshivaTimes, date: nextTimes[index].date, shabatEnterMinBeforeSunset}),
                shabbatExit: store.getShabatExit(location, null, {useYeshivaTimes, date: nextTimes[index].date, shabatExitMinAfterStarsCameOut})
            })
        }else{
            setDateToShow(nextTimes[index])

        }
    }


    const { parasha, shabbatEnter, shabbatExit, fast } = dateToShow || {};

    return <div className={'next-shabbat-container'}>
        <div className={'parash-name-container'}>

            {parasha?.length < 8 ? <div><TextViewer text={"torha_portion"} addChar={':'}/></div>: null}
            {showOne ? <div className={'bold-text'}>{parasha}</div> :
                <Select dropdownMatchSelectWidth={true} value={parasha} onSelect={onParashaSelected}
                        className={"select-next-parasha"}
                >
                    {nextTimes.map((nextTime, index) =>
                        <Select.Option key={`nextTimes-${nextTime.parasha}`}
                                       value={index}>{nextTime.parasha}</Select.Option>)}
                </Select>
            }

        </div>
        <div className={'enter-exit-shabbat-container'}>
            {renderTimeItem({icon: candles, time: shabbatEnter, name: fast ? 'start_fast' :'candle_lighting'})}
            {renderTimeItem({icon: candle, time: shabbatExit, name: fast ? 'end_fast' : 'havdalah'})}
        </div>
    </div>
}

export default NextShbbat;